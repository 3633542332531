<template>
    <div class="pagi-box">
        <el-pagination
            background
            layout="prev, pager, next"
            :current-page=current
            :total="total"
            :page-size="sizes"
            :hide-on-single-page="hide"
            @current-change="pagiChange">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: {
        total: [String,Number], //总数
        current: [String,Number],  //当前页数
        sizes: [String,Number]   //每页显示个数
    },
    data() {
        return {
            hide: true
        }
	},
    watch: {
        total(value){
            let count = this.total / this.sizes
            console.log(count)
            if(count > 1){
                this.hide = false
            }else{
                this.hide = true
			}
        }
    },
    methods: {
        pagiChange(value){
            this.$emit('pagicurrent',value)
        }
    },
}
</script>

<style lang="scss" scope>
    .pagi-box{
        text-align: center;
        padding: 30px 0 0;
        .active{
            background-color: #6EB400 !important;
        }
		.el-pagination.is-background .el-pager li:not(.disabled):hover{
			color: #6EB400;
		}
    }
        
</style>
