<template>
    <div class="picture-multiple">
        <el-upload
            action="/jeecg-boot/sys/common/upload"
            accept="image/*"
            list-type="picture-card"
            :file-list="imglist"
            :headers="headers"
            :before-remove="beforeRemove"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :on-change="handleChange"
            >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">提示: 最多上传五张照片</div>
        </el-upload>
    </div>
</template>

<script>
import { Message } from 'element-ui'
export default {
    props: {
        piclist: Array
    },
    data () {
        return {
            headers: {
                'X-Access-Token': localStorage.getItem('token')
            },
            imglist: [],
        };
    },
    watch: {
        piclist(value){
            let arr = []
            for(let i=0;i<value.length;i++){
                value[i].url = '/jeecg-boot/'+value[i].album
                arr.push(value[i])
            }
            this.imglist = arr
        }
    },
    methods: {
        // 上传成功后的回调
        handleAvatarSuccess(res, file) {
            if(res.success){
                this.$emit('change',res.message)
                Message.success('图片上传成功')
            }else{
                Message.success('图片上传失败')
            }
        },
        // 删除图片之前的回调
        beforeRemove(file,fileList) {
            return this.$confirm('确认删除吗?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

        },
        // 删除图片
        handleRemove(file, fileList) {
            this.$emit('deleteChange',file.response.message)
        },
        handleChange(file, fileList) {
            this.imglist = fileList.slice(-5);
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep.picture-multiple{
    .el-upload--picture-card{
        width: 80px;
        height: 80px;
        line-height: 83px;
        &:hover,&:focus{
            border-color: #67C23A;
            color: #67C23A;
        }
    }
    .el-upload-list__item {
        width: 80px;
        height: 80px;
    }
    .el-upload-list--picture-card {
        .el-upload-list__item-status-label{
            background: #67C23A;
        }
    }
    .el-icon-close-tip{
        display: none !important;
    }
}
</style>
